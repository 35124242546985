<template>
  <div v-if="isReady" class="goods-detail">
    <!-- 顶部和底部 -->
    <openApp :goods-status="goodsStatus" :goods-info="assembleInfo" />
    <!-- banner图片 -->
    <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
      <van-swipe-item v-for="(image, index) in goodsInfo.banner" :key="index">
        <img v-lazy="image" class="banner">
      </van-swipe-item>
    </van-swipe>
    <!-- 商品价格，商品名称，商品描述 -->
    <goodsInfo
      :flash-info="{}"
      :goods-status="goodsStatus"
      :goods-info="goodsInfo"
      :spell-group-info="spellGroupInfo"
    />
    <div class="line" />
    <!-- 成长值，规格，区域，运费 -->
    <goodsHandle
      :goods-info="goodsInfo"
      :goods-status="goodsStatus"
      @getData="againGet"
    />
    <!-- 商品评论 -->
    <AssessList
      :comment="goodsInfo.comment"
      :goods-i-d="goodsInfo.product_id"
      :show-more="true"
    />
    <div v-if="goodsInfo.comment.result.length > 0" class="line" />
    <!-- 商品详情 -->
    <div class="goods-detail-info">
      <div class="tit">商品详情</div>
      <div style="width: 100%" v-html="goodsInfo.goodContent" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, Icon, Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload).use(Icon).use(ActionSheet)

import { getGoodsDetail } from '@/services/goods.js'
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import goodsInfo from '@/component/GoodsDetail/goodsInfo'
import goodsHandle from '@/component/GoodsDetail/goodsHandle'
import AssessList from '@/component/GoodsDetail/assessList'

export default {
  name: 'GoodsListDetail',
  components: {
    OpenApp,
    goodsInfo,
    goodsHandle,
    AssessList
  },
  data() {
    return {
      goodsStatus: 7, // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情；7.拼团详情
      liveId: this.$route.query.liveid || 0, // 直播ID    修正 liveId
      room_id: this.$store.state.room_id || 0, // 关系ID
      robot_send: this.$store.state.robot_send || 0, // 机器人
      liveUserId: this.$route.query.liveUserId || 0, // 直播人ID
      productType: this.$route.query.parentTypeId || 0, // 产品类型
      productId: this.$route.query.goodsId || this.$route.query.product_id || 0, // 产品ID
      productSkuId:
        this.$route.query.skuid || this.$route.query.product_sku_id || 0, // 产品规格ID
      uid: window.localStorage.getItem('uid') || 0, // 用户ID
      goodsInfo: {}, // 商品信息
      assembleInfo: {}, // 装配信息
      spellGroupInfo: '', // 团价
      isReady: false, // 详情接口是否请求完成
      lon: '',
      lat: ''
    }
  },
  mounted() {
    this.$store.commit('changeInterceptUrl', window.location.href)
    this.getGoodsDetail()
  },
  created() {
    const live_id = this.$route.query.liveid || 0
    sessionStorage.setItem('live_id', live_id)
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        function(position) {
          this.lon = position.coords.longitude
          this.lat = position.coords.latitude
        },
        function(e) {
          // store.commit(e.message, true);
        }
      )
    } else {
      // store.commit('位置信息获取失败', true);
    }
  },
  methods: {
    // 领完卷再次调接口
    againGet() {
      this.getGoodsDetail()
    },
    getGoodsDetail() {
      // 获取自营详情
      const data = {
        id: this.$route.query.team_id, // team_id '774',
        uid: this.uid, // 用户ID '1625104',
        lon: this.lon, // '116.52052652994792'
        lat: this.lat // '39.903980848524306',
      }
      getGoodsDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          this.goodsInfo = res.data.productInfo
          this.spellGroupInfo = res.data.assembleInfo.team_price
          // this.goodsInfo = res.data.assembleInfo;
          this.assembleInfo = res.data
          this.isReady = true
        } else {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 75px;
  overflow-x: hidden;

  .banner {
    width: 100%;
    height: 357px;
    background: #999;
  }

  .line {
    width: 100%;
    height: 7px;
    background: #f8f8f8;
  }

  .goods-detail-info {
    width: 100%;
    background: #fff;

    .tit {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 15px;
      color: #141414;
    }

    ::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
